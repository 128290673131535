import { render } from "react-dom";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Form, Input, Card } from "antd";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import OTPVerificationModal from "./OTPVerificationModal";
import openNotification from "./../../../commonFuncs/notification";
import endpoint from "../../../helpers/Endpoint";
const ForgotPassword = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(props.visible);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const handleCancel = () => {
    props.modalClose();
  };
  const submitHandle = async (e) => {
    e.preventDefault();
    let formValidation = true;
    if (email == "") {
      setErrorEmail(true);
      formValidation = false;
    } else {
      setErrorEmail(false);
    }
    let result;
    if (formValidation) {
      try {
        result = await axios.post(
          `${endpoint}/api/v1/treeDriveAdmin/appadmin/forgetpassword`,
          {
            email: email
          }
        );

        if (result.data.status === 200) {
          setIsModalVisible(true);
          openNotification(
            "success",
            "Success",
            "successfully sent the otp to the email.Please check your mail"
          );
        } else if (result && result.data.status != 200) {
          setIsModalVisible(false);
          openNotification("error", "Error", `${result.data.message}`);
        }
      } catch (err) {
        console.log("errr----->", err.message);
        openNotification("error", "Error Occured", err.message);
      }
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const modalClose1 = () => {
    props.modalClose();
    setIsModalVisible(false);
  };

  const handleCancelOTP = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <OTPVerificationModal
        visible={isModalVisible}
        modalClose1={modalClose1}
        email={email}
        handleCancelOTP={handleCancelOTP}
      />

      <Modal
        className="card-all-style d-flex-center-center"
        title="OTP"
        visible={props.visible}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={submitHandle}>
            Submit
          </Button>
        ]}
      >
          <Form.Item
            name={["user", "email"]}
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            rules={[
              {
                type: "email",
                required: true
              }
            ]}
          >
            <Input />
            {errorEmail ? <span style={{ color: "red" }}>Fill email</span> : ""}
          </Form.Item>
      </Modal>
    </>
  );
};
export default ForgotPassword;
