import { UserOutlined} from '@ant-design/icons';
import React,{useState} from "react";
import { Dropdown, Menu, Avatar } from 'antd';

import { useNavigate } from 'react-router-dom';
import openNotification from '../../../commonFuncs/notification';
import ResetPassword from '../../profile/ResetPassword';

const ProfileDrop = () =>{
  let navigate=useNavigate();
  let [resetPassVisible,setResetPassVisible]=useState(false);
  const handleCancelReset=()=>{
    setResetPassVisible(false);
  }
  const showResetModal=()=>{
    setResetPassVisible(true);
  }
  const onClick=({key})=>{
    console.log("cliked on ------>",key);
    switch(key){
      case "logout":
        localStorage.clear();
        navigate("/");
        openNotification("success","logged Out","You are successfully logged out.")
      break;
      case "reset_password":
        showResetModal();
    }
  }
  const menu = (
    <Menu
    onClick={onClick}
      items={[
        {
          key: 'reset_password',
          label: 'Reset Password',
        },
        {
          key: 'logout',
          label: 'Logout',
        },
      ]}
    />
  );
  return  (
    <>
    <ResetPassword visible={resetPassVisible}  handleCancelReset={handleCancelReset}/>
    <Dropdown className='dropprofile' overlay={menu} trigger={['click']}>
      <Avatar
        style={{
          backgroundColor: '#0463EF',
        }}
        icon={<UserOutlined />}
      />
    </Dropdown>
    </>
    
  );
}

export default ProfileDrop;
