import React from 'react';

import {
  MdRestaurant
} from 'react-icons/md';

import { AiOutlineAppstore } from "react-icons/ai";

import {
  FaChartBar,
  FaCoins,
  FaProductHunt,
  FaTruck
} from 'react-icons/fa';

import {
  HomeOutlined,
  TeamOutlined,
  CarOutlined,
  HddOutlined,
  FileTextOutlined,
  AlignLeftOutlined,
  CopyOutlined,
  UnorderedListOutlined
  //  ProductOutlined
} from '@ant-design/icons';

import { NavLink } from 'react-router-dom';

import { Layout, Menu } from 'antd';

const SideNav = () => {

  return (
    <>
      <Menu className='side-nav'
        mode='inline'
        defaultSelectedKeys={['1']}
        items={[
          {
            key: '1',
            icon: <HomeOutlined />,
            label: <NavLink to='/dashboard'>Dashboard</NavLink>,
          },
          {
            key: '2',
            icon: <TeamOutlined />,
            label: <NavLink to='/userlistmain'>Users</NavLink>,
          },
          {
            key: '3',
            icon: <CarOutlined />,
            label: <NavLink to='/driverlistmain'>Drivers</NavLink>,
          },
          {
            key: '4',
            icon: <MdRestaurant />,
            label: <NavLink to='/restaurantlistmain'>Restaurants</NavLink>,
          },
          // {
          //   key: '5',
          //   icon: <GiModernCity />,
          //   label: <NavLink to='/citylist'>Cities</NavLink>,
          // },
          {
            key: '6',
            icon: <FaChartBar />,
            label: <NavLink to='/oppgjor'>Oppgjør</NavLink>,
          },
          {
            key: '7',
            icon: <HddOutlined />,
            label: <NavLink to='/settings'>Setting</NavLink>,
          },
          {
            key: "8",
            icon: <FaCoins />,
            label: <NavLink to="/points">Coin Redemption</NavLink>
          },
          {
            key: "9",
            icon: <AiOutlineAppstore />,
            label: <span>TFL Market</span>,
            children: [
              {
                key: 10,
                icon: <UnorderedListOutlined style={{ color: "black" }} />,
                label: <NavLink to="/tflOrders" style={{ color: "black" }}>TFL Orders</NavLink>
              },
              {
                key: 11,
                icon: <FaProductHunt style={{ color: "black" }} />,
                label: <NavLink to="/tflPoints" style={{ color: "black" }}> TFL Products</NavLink>
              },
              {
                key: 12,
                icon: <FaTruck style={{ color: "black" }} />,
                label: <NavLink to="/postenCharges" style={{ color: "black" }}>Posten Charges</NavLink>
              },
              {
                key: 13,
                icon: <FileTextOutlined style={{ color: "black" }} />,
                label: <span>TFL Reports</span>,
                children: [
                  {
                    key: 14,
                    icon: <AlignLeftOutlined style={{ color: "black" }} />,
                    label: <NavLink to="/tflReport" style={{ color: "black" }}>Daily Report</NavLink>
                  },
                  // {
                  //   key:15, 
                  //   icon:<CopyOutlined   style={{color:"black"}} />,
                  //   label:<NavLink to = "/tflZReport" style={{color:"black"}}>Z-Report</NavLink>
                  // }

                ],
              }

            ],
          },
          // {
          //   key:"10",
          //   icon: <FaCity />,
          //   label:<NavLink to = "/citylist">Cities</NavLink>
          // },
          // {
          //   key:"11",
          //   icon: <FaDonate />,
          //   label:<NavLink to = "/donation">Donation</NavLink>
          // }
        ]}
      />
    </>
  );
};
export default SideNav;