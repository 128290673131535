import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Container from "react-bootstrap/Container";
import { Form, Input, Button, Typography } from "antd";
import { Card } from "antd";
import ezygologo from "../../../assets/ezygo logo.png";
import ForgotPassword from "./ForgotPassword";
import endpoint from "../../../helpers/Endpoint";
import openNotification from "../../../commonFuncs/notification";

const { Title, Text } = Typography;

const Login = () => {
  let navigate = useNavigate();
  const [inputForm, setInputForm] = useState({ username: "", password: "" });
  const [errorObj, setErrorObj] = useState({
    username: false,
    password: false
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const loginHandle = async (e) => {
    e.preventDefault();
    let formValidation = true;

    for (let key in inputForm) {
      if (inputForm[key] === "") {
        setErrorObj((prevObj) => ({ ...prevObj, [key]: true }));
        formValidation = false;
      } else {
        setErrorObj((prevObj) => ({ ...prevObj, [key]: false }));
      }
    }

    if (formValidation) {
      setLoading(true);
      try {
        const result = await axios.post(
          `${endpoint}/api/v1/treeDriveAdmin/appadmin`,
          {
            email: inputForm.username,
            password: inputForm.password
          }
        );

        if (result.data.status === 200) {
          const { token } = result.data.data;
          localStorage.clear();
          localStorage.setItem("token", token);
          openNotification("success", "Logged In", "Successfully Logged In");
          navigate("/dashboard");
        } else {
          openNotification("error", "Error Occurred", result.data.message);
        }
      } catch (err) {
        openNotification("error", "Error Occurred", err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const showModal = () => setIsModalVisible(true);
  const modalClose = () => setIsModalVisible(false);

  return (
    <>
      <ForgotPassword visible={isModalVisible} modalClose={modalClose} />
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(90deg, #000000, #737373)",
          color: "#fff"
        }}
      >
        <Card
          className="card-all-style d-flex-center-center"
          style={{
            width: 600
          }}
        >
          <div className="text-center mb-4">
            <img src={ezygologo} alt="EzyGo Logo" style={{ width: "120px" }} />
          </div>
          <Title level={4} className="text-center">
            Welcome Back!
          </Title>
          <Text className="text-center d-block mb-4">
            Please log in to your account
          </Text>
          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={loginHandle}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              validateStatus={errorObj.username ? "error" : ""}
              help={errorObj.username && "Please enter your username"}
            >
              <Input
                placeholder="Enter your username"
                onChange={(e) =>
                  setInputForm((prevState) => ({
                    ...prevState,
                    username: e.target.value
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              validateStatus={errorObj.password ? "error" : ""}
              help={errorObj.password && "Please enter your password"}
            >
              <Input.Password
                placeholder="Enter your password"
                onChange={(e) =>
                  setInputForm((prevState) => ({
                    ...prevState,
                    password: e.target.value
                  }))
                }
              />
            </Form.Item>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Button
                type="primary"
                htmlType="submit"
                onClick={loginHandle}
                loading={loading}
              >
                Login
              </Button>
              <Button type="link" onClick={showModal}>
                Forgot Password?
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Login;
