import {
  UserOutlined,
  UploadOutlined,
  ConsoleSqlOutlined
} from "@ant-design/icons";
import {
  Row,
  Col,
  Divider,
  Avatar,
  Input,
  Button,
  Select,
  message,
  Upload,
  Form,
  Descriptions,
  Switch
} from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import api from "../../commonFuncs/api";
import openNotification from "../../commonFuncs/notification";

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

export default function Setting(props) {
  const [form] = Form.useForm();
  const [pointsForm] = Form.useForm();

  const [settingsData, setSettingsData] = useState({});

  const [pointsData, setPointsData] = useState({});

  const [errMsg, setErrMsg] = useState("");

  //Get initial settings data from the server
  useEffect(() => {
    getTreeDriveSettingsData();
  }, []);

  //Set initial Form values
  useEffect(() => {
    form.setFieldsValue({
      inputRefferalActive: settingsData.isRefferalActive,
      inputNewUserCouponVal: settingsData.newUserCouponVal,
      inputRefferedUserCouponVal: settingsData.refferedUserCouponVal,
      inputMinOrderAmount: settingsData.minOrderAmount
    });

    pointsForm.setFieldsValue({
      inputPointsActive: pointsData ? pointsData.isPointsActive : false,
      inputNewUserPointsVal: pointsData ? pointsData.newUserPointsVal : 0,
      inputPointsConvVal: pointsData ? pointsData.pointsConvVal : 0,
      // inputPointsConvVal : 10,
      inputNewUserRefPointsVal: pointsData ? pointsData.newUserRefPointsVal : 0,
      inputMinOrderAmount: pointsData ? pointsData.minOrderAmount : 0,
      //inputTotalPoints: (pointsData) ? pointsData.totalPoints : 0,

      inputRange1Min: pointsData ? pointsData.orderRanges?.range1?.minVal : 0,
      inputRange1Max: pointsData ? pointsData.orderRanges?.range1?.maxVal : 0,
      inputRange1PointsVal: pointsData
        ? pointsData.orderRanges?.range1?.pointsVal
        : 0,

      inputRange2Min: pointsData ? pointsData.orderRanges?.range2?.minVal : 0,
      inputRange2Max: pointsData ? pointsData.orderRanges?.range2?.maxVal : 0,
      inputRange2PointsVal: pointsData
        ? pointsData.orderRanges?.range2?.pointsVal
        : 0,

      inputRange3Min: pointsData ? pointsData.orderRanges?.range3?.minVal : 0,
      inputRange3Max: pointsData ? pointsData.orderRanges?.range3?.maxVal : 0,
      inputRange3PointsVal: pointsData
        ? pointsData.orderRanges?.range3?.pointsVal
        : 0,

      inputRange4Min: pointsData ? pointsData.orderRanges?.range4?.minVal : 0,
      inputRange4Max: pointsData ? pointsData.orderRanges?.range4?.maxVal : 0,
      inputRange4PointsVal: pointsData
        ? pointsData.orderRanges?.range4?.pointsVal
        : 0
    });
  }, [
    settingsData.isRefferalActive,
    settingsData.newUserCouponVal,
    settingsData.refferedUserCouponVal,
    settingsData.minOrderAmount,

    pointsData
  ]);

  //Handle form submit
  const handleFormSubmit = () => {
    let formData = {
      inputRequestType: "post",
      inputRefferalActive: settingsData.isRefferalActive,
      inputNewUserCouponVal: settingsData.newUserCouponVal,
      inputRefferedUserCouponVal: settingsData.refferedUserCouponVal,
      inputMinOrderAmount: settingsData.minOrderAmount
    };

    updateSettings(formData, "referral");
  };

  ///handle points form submit
  const handlePointsFormSubmit = (e) => {
    //console.log(e)
    let formData = {
      inputRequestType: "post",
      inputPointsId: pointsData._id ? pointsData._id : "",
      inputNewUserPointsVal: pointsData.newUserPointsVal,
      inputPointsConvVal: pointsData.pointsConvVal,
      inputNewUserRefPointsVal: pointsData.newUserRefPointsVal,
      inputPointsActive: pointsData.isPointsActive,
      inputRange1Max: pointsData.orderRanges.range1.maxVal,
      inputRange1Min: pointsData.orderRanges.range1.minVal,
      inputRange1PointsVal: pointsData.orderRanges.range1.pointsVal,
      inputRange2Max: pointsData.orderRanges.range2.maxVal,
      inputRange2Min: pointsData.orderRanges.range2.minVal,
      inputRange2PointsVal: pointsData.orderRanges.range2.pointsVal,
      inputRange3Max: pointsData.orderRanges.range3.maxVal,
      inputRange3Min: pointsData.orderRanges.range3.minVal,
      inputRange3PointsVal: pointsData.orderRanges.range3.pointsVal,

      inputRange4Max: pointsData.orderRanges.range4.maxVal,
      inputRange4Min: pointsData.orderRanges.range4.minVal,
      inputRange4PointsVal: pointsData.orderRanges.range4.pointsVal,

      inputMinOrderAmount: pointsData.minOrderAmount

      //inputTotalPoints: pointsData.totalPoints
    };

    updatePointsSettings(formData, "points");
  };

  //Method for fetching settings
  const getTreeDriveSettingsData = async () => {
    console.log("---Fetching EzyGo settings data---");

    try {
      setErrMsg("Please wait, fetching information...");

      let formData = {
        inputRequestType: "get"
      };

      //https://api.a-board.tech/api/v1/treeDriveAdmin/appsettings/settings
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/settings`,
        formData
      );

      // console.log("---EzyGo Settings data---", result.data.data);

      // setSettingsData(result.data.data);

      console.log("---EzyGo Settings data---", result.data.data.points);

      setSettingsData(result.data.data.referral);
      setPointsData(result.data.data.points);

      setErrMsg("Information loaded.");
    } catch (err) {
      console.log("error--->", err.message);
      setErrMsg("Error in fetching information");
      openNotification("error", "ERROR", err.message);
    }
  };

  //Method for updating settings
  const updateSettings = async (formData, type) => {
    console.log("---Submit form data---", type, formData);
    //return;

    setErrMsg("Please wait, updating information...");

    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/settings`,
        formData
      );

      console.log("---Settings updated----", result);

      setErrMsg("Settings updated successfully");
    } catch (err) {
      console.log("error--->", err.message);

      setErrMsg("Error in updating information");
      openNotification("error", "ERROR", err.message);
    }
  };

  //Method for updating settings
  const updatePointsSettings = async (formData, type) => {
    console.log("---Submit form data---", type, formData);
    //return;

    setErrMsg("Please wait, updating information...");

    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/settings/points`,
        formData
      );

      console.log("---Settings updated----", result);

      if (result.data.status != 200) {
        setErrMsg(result.data.message);
      } else {
        setErrMsg("Settings updated successfully");
        setPointsData(result.data.data);
      }
    } catch (err) {
      console.log("error--->", err.message);

      setErrMsg("Error in updating information");
      openNotification("error", "ERROR", err.message);
    }
  };

  return (
    <div className="settingPage">
      <div className="add-r-main bg-white pb-2">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            <h3>Referral System Settings</h3>
          </Col>
        </Row>

        <div className="form-r-main p-3">
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            className="add-r-form p-3"
            onFinish={handleFormSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="inputRefferalActive"
                  label="Refferal System Status"
                >
                  <Select
                    onChange={(e) =>
                      setSettingsData({ ...settingsData, isRefferalActive: e })
                    }
                    options={[
                      {
                        value: true,
                        label: "Active"
                      },
                      {
                        value: false,
                        label: "Disabled"
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="inputMinOrderAmount"
                  label="Minimum Order Value"
                  rules={[
                    {
                      required: true,
                      message: "Minimum Order amount is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Minimum Order Value"
                    onChange={(e) =>
                      setSettingsData({
                        ...settingsData,
                        minOrderAmount: e.target.value
                      })
                    }
                  />
                </Form.Item>{" "}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="inputNewUserCouponVal"
                  label="Signup Coupon Value"
                  rules={[
                    {
                      required: true,
                      message: "New User coupon value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="New Signup Coupon Value"
                    onChange={
                      (e) =>
                        setSettingsData({
                          ...settingsData,
                          newUserCouponVal: e.target.value
                        })
                      //console.log(e.target.value)
                    }
                  />
                </Form.Item>{" "}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="inputRefferedUserCouponVal"
                  label="Reffered Coupon Value"
                  rules={[
                    {
                      required: true,
                      message: "Reffered User coupon value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Reffered User Coupon Value"
                    onChange={(e) =>
                      setSettingsData({
                        ...settingsData,
                        refferedUserCouponVal: e.target.value
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="flex justify-content-center pt-3 noTopPadding align-items-center flex-column"
              >
                <Button type="primary" htmlType="submit">
                  Update Settings
                </Button>
                <p className="text-center">{errMsg}</p>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <div className="add-r-main bg-white pb-2 mt-3">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            <h3>Point System Settings</h3>
          </Col>
        </Row>

        <div className="form-r-main p-3">
          <Form
            {...layout}
            form={pointsForm}
            name="nest-messages"
            className="add-r-form"
            onFinish={handlePointsFormSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="inputPointsActive" label="Point System Status">
                  <Select
                    //defaultValue = "false"
                    onChange={(e) =>
                      //console.log('---Select value----', e)
                      setPointsData({ ...pointsData, isPointsActive: e })
                    }
                    options={[
                      {
                        value: true,
                        label: "Active"
                      },
                      {
                        value: false,
                        label: "Disabled"
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="inputPointsConvVal"
                  label="Point Conversion Value (for TFL Products)"
                  rules={[
                    {
                      required: true,
                      message: "Point Conversion Value is required!"
                    }
                  ]}
                  help={
                    "Note : For eg. 1 Point = Kr. " + pointsData.pointsConvVal
                  }
                >
                  <Input
                    // prefix={<p className="mb-0">Point Conversion</p>}
                    placeholder="Point Conversion"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        pointsConvVal: e.target.value
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Descriptions bordered className="my-4">
              <Descriptions.Item label="Total Points">
                {pointsData.totalPoints}
              </Descriptions.Item>
              <Descriptions.Item label="Available Points">
                {pointsData.availablePoints}
              </Descriptions.Item>
              <Descriptions.Item label="Redeemed Points">
                {pointsData.redeemedPoints}
              </Descriptions.Item>
            </Descriptions>

            <Row className="pointSystemSetting">
              <Col className="col-4">
                <Form.Item
                  name="inputNewUserPointsVal"
                  label="Signup Point Value"
                  rules={[
                    {
                      required: true,
                      message: "Signup User point value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Signup User Point Value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        newUserPointsVal: e.target.value
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputNewUserRefPointsVal"
                  label="Referral Point Value"
                  rules={[
                    {
                      required: true,
                      message: "Referral User point value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Referral User Point Value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        newUserRefPointsVal: e.target.value
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputMinOrderAmount"
                  label="Minimum Order Value"
                  rules={[
                    {
                      required: true,
                      message: "Minimum order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Minimum Order Value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        minOrderAmount: e.target.value
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="col-4">
                <Form.Item
                  name="inputRange1Min"
                  label="Range 1 Min"
                  rules={[
                    {
                      required: true,
                      message: "Range 1 min order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 1 min order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range1: {
                            ...pointsData?.orderRanges?.range1,
                            minVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange1Max"
                  label="Range 1 Max"
                  rules={[
                    {
                      required: true,
                      message: "Range 1 max order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 1 max order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range1: {
                            ...pointsData?.orderRanges?.range1,
                            maxVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange1PointsVal"
                  label="Points Value"
                  rules={[
                    {
                      required: true,
                      message: "Range 1 points value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 1 points value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range1: {
                            ...pointsData?.orderRanges?.range1,
                            pointsVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="col-4">
                <Form.Item
                  name="inputRange2Min"
                  label="Range 2 Min"
                  rules={[
                    {
                      required: true,
                      message: "Range 2 min order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 2 min order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range2: {
                            ...pointsData?.orderRanges?.range2,
                            minVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange2Max"
                  label="Range 2 Max"
                  rules={[
                    {
                      required: true,
                      message: "Range 2 max order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 2 max order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range2: {
                            ...pointsData?.orderRanges?.range2,
                            maxVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange2PointsVal"
                  label="Points Value"
                  rules={[
                    {
                      required: true,
                      message: "Range 2 points value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 2 points value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range2: {
                            ...pointsData?.orderRanges?.range2,
                            pointsVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="col-4">
                <Form.Item
                  name="inputRange3Min"
                  label="Range 3 Min"
                  rules={[
                    {
                      required: true,
                      message: "Range 3 min order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 3 min order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range3: {
                            ...pointsData?.orderRanges?.range3,
                            minVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange3Max"
                  label="Range 3 Max"
                  rules={[
                    {
                      required: true,
                      message: "Range 3 max order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 3 max order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range3: {
                            ...pointsData?.orderRanges?.range3,
                            maxVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange3PointsVal"
                  label="Points Value"
                  rules={[
                    {
                      required: true,
                      message: "Range 3 points value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 3 points value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range3: {
                            ...pointsData?.orderRanges?.range3,
                            pointsVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="col-4">
                <Form.Item
                  name="inputRange4Min"
                  label="Range 4 Min"
                  rules={[
                    {
                      required: true,
                      message: "Range 4 min order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 4 min order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range4: {
                            ...pointsData?.orderRanges?.range4,
                            minVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange4Max"
                  label="Range 4 Max"
                  rules={[
                    {
                      required: true,
                      message: "Range 4 max order value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 4 max order value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range4: {
                            ...pointsData?.orderRanges?.range4,
                            maxVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="col-4">
                <Form.Item
                  name="inputRange4PointsVal"
                  label="Points Value"
                  rules={[
                    {
                      required: true,
                      message: "Range 4 points value is required!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Range 4 points value"
                    type="number"
                    onChange={(e) =>
                      setPointsData({
                        ...pointsData,
                        orderRanges: {
                          ...pointsData?.orderRanges,
                          range4: {
                            ...pointsData?.orderRanges?.range4,
                            pointsVal: e.target.value
                          }
                        }
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className=" justify-content-center flex-column align-items-center">
              <Button type="primary" htmlType="submit">
                Update Points Settings
              </Button>
              <p className="text-center">{errMsg}</p>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}
