import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Row,
  Col,
  Divider,
  DatePicker,
  Tooltip,
  Select,
  Button,
  Tag
} from "antd";
import moment from "moment";
import api from "../../commonFuncs/api";
import formatDateTwoWeeks from "../../commonFuncs/moment/endStartTwoWeeks";
import startEndDay from "../../commonFuncs/moment/endStart";
import s3url from "../../helpers/s3";
import { NavLink } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { socket } from "../../helpers/Socket";
import TableSumX from "./../../components/Table/TableSumX";

const TFLOrders = (props) => {
  const [option, setOption] = useState("ALL");
  const [allData, setAllData] = useState([]);
  const [onlineDate, setOnlineDate] = useState("");
  const [filterOrdered, setFilteredOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const { Option } = Select;

  useEffect(() => {
    socket.on("TFLPayment", async (data) => {
      console.log("----Socket received----");
      const date = new Date() ? new Date() : moment().date()._d;
      filterDate(date);
    });
  }, []);

  useEffect(() => {
    //fetchAllOrders();
    console.log("filter data is-----", filterOrdered);
  }, [filterOrdered]);

  useEffect(() => {
    console.log("statusChanged--------------");
    setLoading(true);
    const date = onlineDate ? onlineDate : moment().date()._d;
    filterDate(date);
  }, [statusChanged]);

  const fetchAllOrders = async (data) => {
    try {
      console.log("api data", data);
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/getTflOrders`,
        data
      );
      console.log("result in oppogjor=====>", result.data.data);
      setAllData(result.data.data);
      setFilteredOrder(result.data.data);
      setLoading(false);
    } catch (err) {
      console.log("error--->", err.message);
    }
  };

  const filteredResult = (e) => {
    console.log("cjkkklk====", e);
    setOption(e);

    if (e.toLowerCase() != "all") {
      let filterData = allData.filter((val) => {
        return val.orderStatus.toLowerCase() == e.toLowerCase();
      });
      setFilteredOrder(filterData);
    } else {
      setFilteredOrder(allData);
    }
  };

  const filterDate = (date) => {
    setOnlineDate(date);
    const startDate = moment(date);
    //console.log("date =====", startDate)
    const endDate = moment(date).add(1, "days");
    let postData = {
      startDate: startDate,
      endDate: endDate
    };
    console.log("======data=====", postData);
    fetchAllOrders(postData);
  };

  const columns = [
    {
      title: "S.No",
      key: "orderNumber",
      width: "5%",
      fixed: "center",
      render: (record, item, index) => {
        return <>{index + 1}</>;
      }
    },
    {
      title: "Order Date",
      key: "orderDate",
      width: "12%",
      render: (text, record) => {
        return <>{`${moment(record.createdAt).format("MMMM DD, YYYY")}`}</>;
      }
    },
    {
      title: "Order Time",
      key: "orderTime",
      width: "10%",
      render: (text, record) => {
        return <>{`${new Date(record.createdAt).toLocaleTimeString()}`}</>;
      }
    },
    {
      title: "No. of Products",
      key: "noProducts",
      width: "9%",
      render: (text, record) => {
        return <>{record.items.length}</>;
      }
    },
    {
      title: "Status",
      key: "status",
      width: "10%",
      render: (text, record) => {
        let color;
        color = record.orderStatus == "DELIVERED" ? "green" : record.orderStatus == "IN-PROCESS" ?  "orange" : record.orderStatus == "CANCEL" ? "red" : record.orderStatus == "SHIPPED" ? "cyan" : "";
          if (record.orderStatus == "DELIVERED") {
              return (  
                  <Tag color={color}>{record.orderStatus}</Tag>
              );
          } else if (record.orderStatus == "PENDING") {
              return (
                  <span
                      style={{
                          textAlign: "center",
                          borderRadius: 5,
                          color: "black",
                          opacity: ".65"
                      }}
                  >
                      {record.orderStatus}
                  </span>
                  
              );
          } else if (record.orderStatus == "IN-PROCESS") {
              return (
                <Tag color={color}>{record.orderStatus}</Tag>
              );
          }
          else if (record.orderStatus == "SHIPPED") {
            return (
              <Tag color={color}>{record.orderStatus}</Tag>
            );
        }
          else if (record.orderStatus == "CANCEL") {
              return (
                <Tag color={color}>{record.orderStatus}</Tag>
              );
          } else {
              return record.orderStatus;
          }
      }
    },
    {
      title: "Order From",
      key: "OrderFrom",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <> {record.customerType == "AND" ? "Android" : "IOS"}</>
            <> - {record.orderFrom == "TDP" ? "EzyGo" : "Web"}</>
          </>
        );
      }
    },
    {
      title: "Total",
      key: "total",
      width: "10%",
      render: (text, record) => {
        return <> {`Kr. ${record.totalAmount.toFixed(2)}`} </>;
      }
    },
    {
      title: "Action",
      //key: "view",
      width: "10%",
      // className:"view-title",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View" placement="top" color={"blue"}>
            <span>
              <NavLink to="/viewTFLOrders" state={{ record: record }}>
                <EyeOutlined />
              </NavLink>
            </span>
          </Tooltip>
        </Space>
      )
    }
  ];

  let totalOrderPrice = 0;
  filterOrdered.map((e) => {
    totalOrderPrice += e.totalAmount;
  });

  const ReportSummaryData = [
    {
      key: "-1",
      render: () => "test"
    }
  ];

  const ReportSummary = (totalOrderPrice) => [
    {
      title: "S.No",
      key: "S.No",
      width: "58%",
      render: () => <strong>Total</strong>
    },
    {
      title: "Total",
      key: "total",
      render: () => `Kr.${totalOrderPrice.toFixed(2)}`
    }
  ];

  return (
    <>
      <div className="op-table">
        <Row className="justify-content-between">
          <h4>
            TFL Orders : {filterOrdered.length ? filterOrdered.length : 0}
          </h4>
          <Col>
            <Space direction="vertical">
              <Select
                defaultValue="ALL"
                style={{
                  width: 120
                }}
                //onChange={onOptionsSelect}
                onChange={filteredResult}
                value={option}
              >
                <Option value="ALL">ALL</Option>
                <Option value="PENDING">PENDING</Option>
                <Option value="IN-PROCESS">IN-PROCESS</Option>
                <Option value="DELIVERED">DELIVERED</Option>
                <Option value="CANCEL">CANCEL</Option>
              </Select>
            </Space>
            <Space direction="vertical">
              <DatePicker
                onChange={(date, dateString) => {
                  filterDate(date);
                }}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </Space>
          </Col>
        </Row>
        <Divider />

        <Table
          scroll={{ x: true }}
          className="user-table2"
          loading={loading}
          columns={columns}
          dataSource={filterOrdered}
          striped
          bordered
          hover
          rowKey={props.rowKey}
        />
      </div>
    </>
  );
};

export default TFLOrders;
